*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --bg-color: #fafafa;
    --bg-gradient: linear-gradient(
        105deg,
        rgba(184, 203, 237, 1) 0%,
        rgba(222, 245, 221, 1) 100%
    );
    --bg-border: linear-gradient(
        90deg,
        rgba(124, 166, 252, 1) 0%,
        rgba(119, 235, 129, 1) 100%
    );
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
}

.app main {
    padding: 10px;
    height: 100%;
    overflow: auto;
}
.leaflet-container {
    height: 87vh;
    width: 100wh;
  }
