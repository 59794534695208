.pokemon {
    height: 85vh;
}
.pokeballButton {
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    background: none;
    padding: 50px 50px 0 50px;
}
.pokeballImg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}
.pokemonInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
}
.pokemonInfoImg {
    width: 100px;
    margin: 100px 0;
}
.pokemonTitle {
    margin: 5px 0;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Pokemon Solid', 'sans-serif'
                                    
}
.pokemonInfoText {
    font-family: 'Courier New', Courier, monospace;
}
.bgWrap {
    position: relative;
    overflow: hidden;
}
.bg {
    position: absolute;
    background-size: cover;
    opacity: 0.4;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
}
.bgContent {
    position: relative;
}


